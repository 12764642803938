import React, { useState, useEffect } from "react"
import { Layout } from "../components/layout"
import TOUR_DATES from "../data/tour_dates.json"
import dayjs from "dayjs"
import ReactPlayer from "react-player/lazy"

export default function Tour() {
  const [showTour, setShowTour] = useState(false)

  useEffect(() => {
    let timer2 = setTimeout(() => setShowTour(true), 600)

    return () => {
      clearTimeout(timer2)
    }
  }, [])

  return (
    <Layout>
      <div className={`tour-wrapper ${showTour ? "show--tour" : ""} `}>
        {TOUR_DATES.length > 0 ? (
          TOUR_DATES.map((o, idx) => {
            const { date, city, state, country, venue, link } = o

            return (
              <div key={idx} className="tourItem">
                <div className="tourdates_info">
                  <h1>{dayjs(date).format("MMM D, YYYY")}</h1>
                  <div className="tourdates_venuelocation">
                    <h2>
                      {city}, {state}
                    </h2>
                    <h2>{venue}</h2>
                  </div>
                </div>
                <div className="tourdates__actions">
                  <a href={link}>Tickets</a>
                </div>
              </div>
            )
          })
        ) : (
          <h1 className="coming-soon">LIVE DATES COMING SOON</h1>
        )}

        <div className="video-wrapper">
          <ReactPlayer
            url={
              "https://cdn.glitch.me/16cef9d5-10f3-4b7e-b16a-c9423d70a5f2/Willsass%2077-1.mp4?v=1665526351660"
            }
            width="100%"
            height="100%"
            controls={true}
            className="content-detail-video size"
          />

          <ReactPlayer
            url={
              "https://cdn.glitch.me/16cef9d5-10f3-4b7e-b16a-c9423d70a5f2/Onemoretime-1.mp4?v=1665517041832"
            }
            width="100%"
            height="100%"
            controls={true}
            className="content-detail-video"
          />
          <ReactPlayer
            url={
              "https://cdn.glitch.global/16cef9d5-10f3-4b7e-b16a-c9423d70a5f2/Will%20Sass%20Zouk%20Raw%204-1.mp4?v=1665521759062"
            }
            width="100%"
            height="100%"
            controls={true}
            className="content-detail-video"
          />
          <ReactPlayer
            url={
              "https://cdn.glitch.global/16cef9d5-10f3-4b7e-b16a-c9423d70a5f2/Ws%20Horizontal%20-%2015-1.mp4?v=1665521769191"
            }
            width="100%"
            height="100%"
            controls={true}
            className="content-detail-video"
          />
        </div>
      </div>

      {/* <ReactPlayer
        url={
          "https://cdn.glitch.global/16cef9d5-10f3-4b7e-b16a-c9423d70a5f2/Willsass%207-1.mp4?v=1665521774483"
        }
        width="100%"
        height="100%"
        controls={true}
        className="content-detail-video"
      />
      <ReactPlayer
        url={
          "https://cdn.glitch.me/16cef9d5-10f3-4b7e-b16a-c9423d70a5f2/Img%205330-1.mp4?v=1665521784299"
        }
        width="100%"
        height="100%"
        controls={true}
        className="content-detail-video"
      /> */}
    </Layout>
  )
}

// {
//   "date": "2022-05-16",
//   "city": "Houston",
//   "state": "TX",
//   "country": "US",
//   "venue": "House of Blues",
//   "link": "https://www.ticketmaster.com/event/3A005C71EB057F4B"
// },
